.center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 100vh; /* Set the height of the container to 100% of the viewport height */
  }
  
  .center-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    /* Additional styling for your centered content goes here */
  }


  .fade-in-container {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .fade-in-container.visible {
    opacity: 1;
  }
  
  