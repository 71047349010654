input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  min-width: 500px;
  max-width: 600px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.sidebar {
  /* border: 1px solid #ddd; */
  border: 1px solid royalblue;
  border-radius: 5px;
  padding: 10px;
}
 
.is-sticky {
  position: fixed;
  top: 10px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.connect-container {
  height:300px;
  width:300px;
  background-color: rgba(0, 0, 0, 0.5); /* Example: semi-transparent black background */
  border: 1px solid blue; /* Example: blue border */
  /* Add more styles as needed */
}