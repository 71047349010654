/* Custom styles for the carousel dots */
.carousel .control-dots .dot {
    background-color: #ccc; /* Default color of the dots */
    opacity: 1; /* Ensure full opacity */
    box-shadow: none; /* Remove any shadow */
}
.carousel .control-dots .dot.selected {
    background-color: #2499EF; /* Color of the selected dot */
    box-shadow: 0 0 5px gray; /* Optional: Add a subtle glow effect */
}
